import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Link,
  Stack,
} from '@mui/material';
import { useEffect } from 'react';

import {
  AddQuestionHeaderBar,
  EditQuestionSideBar,
  MainBarSelectors,
  PaginationFooter,
  QuestionList,
} from '@/components';
import { getWebsites } from '@/request/cliengo-api';
import {
  getCategories,
  getChatbotByWebsiteId,
  getFaqs,
} from '@/request/hubbot-api';
import { getGptConfigByWebsiteId } from '@/request/technical-solutions-platform';
import {
  category,
  isLoading,
  isQuestionsLoading,
  selectedView,
  selectedWebsite,
  websites,
} from '@/signals';

export const KnowledgeBaseView = () => {
  const initData = async () => {
    category.value = [];
    selectedView.value = 'knowledgeBase';
    isLoading.value = true;
    if (!websites.value.length) {
      await getWebsites().catch((error) => console.error(error));
      if (selectedWebsite.value) {
        const result = await getChatbotByWebsiteId(selectedWebsite.value.id);

        await getCategories();

        if (!result) {
          await getGptConfigByWebsiteId(selectedWebsite.value.id);
        }
      }
    }
    await getFaqs();
    isLoading.value = false;
  };

  useEffect(() => {
    initData().catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <Box sx={{ width: '100%', maxWidth: '1366px' }}>
      <Stack direction={'column'} spacing={2} sx={{ marginTop: 2 }}>
        <Card sx={{ boxShadow: 1, borderRadius: 0.75 }}>
          <MainBarSelectors />
        </Card>

        <Card
          sx={{
            boxShadow: 1,
            borderRadius: 0.75,
            minHeight: '800px',
            display: 'grid',
          }}
        >
          <AddQuestionHeaderBar />

          {isQuestionsLoading.value ? (
            <Box className="h-screen flex-center" maxHeight={550}>
              <CircularProgress size="4rem" thickness={5} />
            </Box>
          ) : (
            <div className="main-grid__container-list">
              <Box className="main-grid__list" maxHeight={650}>
                <QuestionList />
              </Box>

              <Box maxHeight={650}>
                <EditQuestionSideBar />
              </Box>
            </div>
          )}

          <Divider
            sx={{
              m: 0.5,
            }}
          />
          <PaginationFooter />
        </Card>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          py: 1,
        }}
      >
        <Link href="https://storyset.com/online">
          Online illustrations by Storyset
        </Link>
      </Box>
    </Box>
  );
};
