import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { ChangeEvent } from 'react';

import { getItems } from '@/request/hubbot-api';
import { currentPage, totalPages } from '@/signals';

export const PaginationComponentItem = () => {
  const changePage = async (page: number) => {
    currentPage.value = page;
    await getItems();
  };

  const handlePaginationChange = (e: ChangeEvent<unknown>, page: number) => {
    void changePage(page);
  };

  return (
    <Pagination
      variant="outlined"
      shape="rounded"
      className="flex-center"
      count={totalPages.value}
      onChange={handlePaginationChange}
      hidePrevButton={currentPage.value === 1}
      hideNextButton={currentPage.value === totalPages.value}
      renderItem={(item) => (
        <PaginationItem {...item} selected={currentPage.value === item.page} />
      )}
    />
  );
};
