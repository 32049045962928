/* eslint-disable react-func/max-lines-per-function */
import { ItemsApiResponse } from './types';

import {
  // chatbot,
  currentPage,
  isItemsLoading,
  items,
  selectedWebsite,
  itemsPerPage,
  selectedItem,
  totalPages,
  totalItems,
  category,
  questionFilter,
} from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const getItems = async () => {
  isItemsLoading.value = true;
  try {
    const client = HubbotApiHttpClient.instance;

    selectedItem.value = null;
    /*
    if (questionFilter.value) {
      const url = `/recommender/${selectedWebsite.value?.companyId}/search`;
      const search = {
        companyId: itemsConfig.value?.companyId,
        provider: itemsConfig.value?.provider,
        nameStore: itemsConfig.value?.chatbotId,
        q: questionFilter,
      };
      const response = await client.post<Item[]>(url, search);

      items.value = response;
      totalItems.value = 1;
      totalPages.value = 1;
      return response;
    }()*/
    if (selectedWebsite.value) {
      const response = await client.get<ItemsApiResponse>(buildGetUrl());

      items.value = response.result;
      totalItems.value = response.meta.total;
      totalPages.value = Math.ceil(response.meta.total / itemsPerPage.value);
      return response.result;
    } else {
      items.value = [];
      currentPage.value = 1;
      totalItems.value = 0;
      totalPages.value = 1;
      return [];
    }
  } catch (error) {
    console.error(error);
  } finally {
    isItemsLoading.value = false;
  }
};

const buildGetUrl = () => {
  // return `/recommender/${selectedWebsite.value?.companyId}/items?limit=${
  let url = `/recommender/${selectedWebsite.value?.companyId}/items?limit=${
    itemsPerPage.value
  }&offset=${(currentPage.value - 1) * itemsPerPage.value}`;

  if (category.value && category.value.length > 0) {
    const flattenedCategories = category.value.flat().join(',');

    url = `${url}&categories=${flattenedCategories}`;
  }
  if (questionFilter.value) {
    url = `${url}&q=${questionFilter.value}`;
  }

  return url;
};
