import { Grid, Stack, Typography } from '@mui/material';

import { SearchBar } from '@/components';
import { itemsConfig } from '@/signals';

export const BarItemCategories = () => {
  return (
    <Grid container>
      <Grid item xs={6} md={7} px={2}>
        <Stack spacing={1}>
          {itemsConfig.value === null ? (
            <Typography className="!mb-3 text-gray-800">
              Este canal no tiene un chatbot con recomendador de items
              configurado
            </Typography>
          ) : (
            <SearchBar />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
