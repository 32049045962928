export type viewName = 'knowledgeBase' | 'recommender' | 'report';

export const views: Record<
  viewName,
  {
    label: string;
    route: string;
  }
> = {
  knowledgeBase: {
    label: 'Base de conocimiento',
    route: '/knowledge-base/',
  },
  recommender: {
    label: 'Productos',
    route: '/recommender/',
  },
  report: {
    label: 'Reporte',
    route: '/report/',
  },
};
