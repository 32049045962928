import { Container } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { KnowledgeBaseView } from './KnowledgeBaseView';
import { RecommenderBaseView } from './RecommenderBaseView';
import MainAppBar from './MainAppBar';
import { ReportView } from './ReportView';
import { views } from './constants';

import { getWebsites } from '@/request/cliengo-api';
import { getChatbotByWebsiteId } from '@/request/hubbot-api';
import { getGptConfigByWebsiteId } from '@/request/technical-solutions-platform';
import { isLoading, selectedWebsite } from '@/signals';

export const MainView = () => {
  const initData = async () => {
    isLoading.value = true;
    await getWebsites().catch((error) => console.error(error));
    if (selectedWebsite.value) {
      const result = await getChatbotByWebsiteId(selectedWebsite.value.id);

      if (!result) {
        await getGptConfigByWebsiteId(selectedWebsite.value.id);
      }
    }
    isLoading.value = false;
  };

  useEffect(() => {
    initData().catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <Container
      sx={{
        p: 1,
        width: '100% !important',
        maxWidth: '1366px !important',
        backgroundColor: '#eeeeee ' /*'#DCE2E6'*/,
      }}
    >
      <MainAppBar />
      <Routes>
        <Route
          path={views['knowledgeBase'].route}
          element={<KnowledgeBaseView />}
        />
        <Route
          path="/"
          element={<Navigate to={views['knowledgeBase'].route} />}
        />
        <Route
          path="/*"
          element={<Navigate to={views['knowledgeBase'].route} />}
        />
        <Route
          path={views['recommender'].route}
          element={<RecommenderBaseView />}
        />

        <Route path={views['report'].route} element={<ReportView />} />
      </Routes>
    </Container>
  );
};
