import { Box, FormControl, MenuItem, Select } from '@mui/material';

import {
  getFaqs,
  getCategories,
  getChatbotByWebsiteId,
} from '@/request/hubbot-api';
import { getGptConfigByWebsiteId } from '@/request/technical-solutions-platform';
import {
  category,
  categoryFilter,
  isLoading,
  questionFilter,
  selectedWebsite,
  websites,
} from '@/signals';

export const WebsitesSelect = () => {
  const handleWebsiteChange = async (websiteId: string): Promise<void> => {
    isLoading.value = true;
    selectedWebsite.value =
      websites.value.find((website) => website.id === websiteId) || null;

    const result = await getChatbotByWebsiteId(websiteId);

    if (!result) {
      await getGptConfigByWebsiteId(websiteId);
    }

    category.value = [];
    categoryFilter.value = '';
    questionFilter.value = '';

    await getCategories();
    await getFaqs();
    isLoading.value = false;
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
        <Select
          labelId="website-select-label"
          id="website-select"
          value={selectedWebsite.value?.id || ''}
          onChange={(e) => {
            if (e.target.value && typeof e.target.value === 'string') {
              void handleWebsiteChange(e.target.value);
            }
          }}
        >
          {websites.value.map((website) => {
            return (
              <MenuItem key={website.id} value={website.id}>
                {website.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
