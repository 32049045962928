import { KotlerApiHttpClient } from '@/utils/http-client/kotler-api-http-client copy';

interface ProductData {
  name: string;
  full_description: string;
  [key: string]: number | string | boolean | string[] | null;
}
interface ApiResponse {
  product_data: ProductData;
}
interface ProductDetails {
  [key: string]: number | string | boolean | string[] | null;
}

const checkScrap = (
  paramsScrap: ProductDetails,
  productDetails: ProductDetails
) => {
  if (paramsScrap) {
    const productDetailsKeys = Object.keys(productDetails);
    const paramsKeys = Object.keys(paramsScrap);

    const allKeysPresent = productDetailsKeys.every((key) =>
      paramsKeys.includes(key)
    );

    if (allKeysPresent) {
      return true;
    }

    return false;
  } else {
    return false;
  }
};
const postProductData = async (url: string, productDetails: ProductDetails) => {
  const client = KotlerApiHttpClient.instance;
  const response = await client.post<ApiResponse>(`/product-data`, {
    website: [url],
    product_details: productDetails,
  });

  return response.product_data;
};
const formatItem = (response: ProductData) => {
  const name = response?.name ?? '';
  const newName = capitalizeFirstLetter(name);
  const newItem = {
    name: newName,
    description: response?.full_description ?? '',
    params: response ?? {},
  };

  return newItem;
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const scrapItem = async (
  url: string,
  productDetails: ProductDetails
) => {
  try {
    const paramsScrap = await postProductData(url, productDetails);

    if (checkScrap(paramsScrap, productDetails)) {
      return formatItem(paramsScrap);
    } else {
      const secondParamsScrap = await postProductData(url, productDetails);

      return formatItem(secondParamsScrap);
    }
  } catch (error) {
    console.error(error);
  }
};
