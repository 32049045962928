import { ItemsCategoriesApiResponse } from './types';

import { categories, itemsConfig, selectedWebsite } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const getItemsCategories = async (): Promise<string[]> => {
  if (selectedWebsite.value) {
    try {
      const client = HubbotApiHttpClient.instance;
      const response = await client.get<ItemsCategoriesApiResponse>(
        `/recommender/${selectedWebsite.value?.companyId}/config`
      );

      const sortedCategories = response.categories.sort();

      if (response) {
        categories.value = sortedCategories;
        itemsConfig.value = response;

        return sortedCategories;
      } else {
        categories.value = [];
      }
    } catch (error) {
      console.error(error);
    }
  }

  categories.value = [];
  return [];
};
