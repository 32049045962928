import { EditQuestionApiResponse } from './types';

import { HUBBOT_API_URL } from '@/constants/environment';
import { Question, chatbot } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const updateFaq = async (question: Question, id: string) => {
  const client = HubbotApiHttpClient.instance;

  try {
    if (chatbot.value) {
      const response = await client.put<EditQuestionApiResponse>(
        `${HUBBOT_API_URL}/faqs/${chatbot.value.chatbotId}/${id}`,
        { ...question }
      );

      return response;
    }
  } catch (error) {
    console.error(error);
  }
};
