import { Box, Stack, Typography } from '@mui/material';

import {
  PaginationComponentItem,
  PaginationSizeSelectItem,
} from '@/components';
import { totalItems } from '@/signals';

export const PaginationFooterItems = () => {
  return (
    <Stack sx={{ justifyContent: 'space-between' }} direction={'row'}>
      <Box sx={{ p: 2, alignItems: 'center' }}>
        <Typography>Cantidad de items: {totalItems.value}</Typography>
      </Box>

      <PaginationComponentItem />

      <Stack sx={{ p: 1, alignItems: 'center' }} direction={'row'} spacing={1}>
        <Typography>Items por página: </Typography>
        <PaginationSizeSelectItem />
      </Stack>
    </Stack>
  );
};
