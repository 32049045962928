import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Popover,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

import { FilterSearchBar } from './FilterSearchBar.component';

import { category, filterCategories, selectedView } from '@/signals';
import { getAllData } from '@/request/hubbot-api/getAllData';
import { getCategories, getItemsCategories } from '@/request/hubbot-api';

export const FilterComponent = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const [checkedCategories, setCheckedCategories] = useState<
    Record<string, string | boolean>
  >({});

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;

    setCheckedCategories({
      ...checkedCategories,
      [name]: e.target.checked,
    });

    if (e.target.checked) {
      category.value = [...category.value, name];
    } else {
      category.value = category.value.filter((e) => e !== name);
    }
    void getAllData();
  };

  const clearSelection = () => {
    setCheckedCategories({});
    category.value = [];
    void getAllData();
  };

  useEffect(() => {
    if (selectedView.value === 'recommender') {
      void getItemsCategories();
    } else {
      void getCategories();
    }
  }, []);

  return (
    <div>
      <button
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        className="flex gap-1"
      >
        <FilterListRoundedIcon
          fontSize="small"
          color={!category.value ? 'disabled' : 'primary'}
        />
        <Typography color={!category.value ? 'GrayText' : 'primary'}>
          Filtro por categoría
        </Typography>
      </button>

      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="overflow-auto max-h-[350px] max-w-[340px] flex-col p-3">
          <FormGroup>
            <FilterSearchBar />
            <Divider className="!mt-3" />

            <button className="m-2" onClick={clearSelection}>
              <Typography>Limpiar</Typography>
            </button>
            <Divider className="!mb-3" />

            {filterCategories.value.map((category) => (
              <FormControlLabel
                key={category}
                control={
                  <Checkbox
                    name={category}
                    checked={!!checkedCategories[category]}
                    onChange={handleChangeCheckbox}
                  />
                }
                label={category}
              />
            ))}
          </FormGroup>
        </div>
      </Popover>
    </div>
  );
};
