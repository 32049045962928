import { Box, Stack, Typography } from '@mui/material';

import { PaginationComponent, PaginationSizeSelect } from '@/components';
import { totalQuestions } from '@/signals';

export const PaginationFooter = () => {
  return (
    <Stack sx={{ justifyContent: 'space-between' }} direction={'row'}>
      <Box sx={{ p: 2, alignItems: 'center' }}>
        <Typography>Cantidad de preguntas: {totalQuestions.value}</Typography>
      </Box>

      <PaginationComponent />

      <Stack sx={{ p: 1, alignItems: 'center' }} direction={'row'} spacing={1}>
        <Typography>Preguntas por página: </Typography>
        <PaginationSizeSelect />
      </Stack>
    </Stack>
  );
};
