import { createTheme } from '@mui/material';

import { colors } from './colors';
import { OverrideChipFilled } from './overrideHelpers/OverrideChip';

export const theme = createTheme({
  typography: {
    fontFamily: 'Nunito, sans-serif',
  },
  palette: {
    primary: {
      light: '#BCA8FD',
      dark: '#7460F5',
      ...colors.primary,
    },
    secondary: {
      ...colors.secondary,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'black',
          fontWeight: 'bold',
          fontSize: '15px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            color: '#fff',
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          borderRadius: 999,
          paddingLeft: 24,
          paddingRight: 24,
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      variants: [...OverrideChipFilled()],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '1rem',
          borderRadius: '8px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: '1rem',
          borderRadius: '8px',
          boxShadow: 'none',
          borderColor: 'red',
          border: '1px solid red',
          boxSizing: 'border-box',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: 'none',
          padding: 16,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'helper' },
          style: {
            fontSize: 12,
          },
        },
      ],
    },
  },
});
