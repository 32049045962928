import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import { AddQuestionModal } from '@/components';
import { chatbot } from '@/signals';

export const AddQuestionHeaderBar = () => {
  return (
    <Box className="main-grid__container-list">
      <Box sx={{ px: 2 }}>
        <Grid container>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ alignItems: 'center', display: 'inline-flex' }}
          >
            <Stack>
              <Typography
                fontWeight="bold"
                variant="h6"
                className="text-gray-800"
              >
                Lista de preguntas
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            px: 2,
            maxWidth: '600px',
          }}
        >
          <AddQuestionModal
            button={
              <Button
                variant="contained"
                startIcon={<AddIcon fontSize="small" />}
                disabled={!chatbot.value}
              >
                Agregar pregunta
              </Button>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
