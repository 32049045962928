import { DeleteQuestionApiResponse } from './types';

import { chatbot } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const deleteFaq = async (id: string) => {
  try {
    const client = HubbotApiHttpClient.instance;

    if (chatbot.value) {
      const response = await client.delete<DeleteQuestionApiResponse>(
        `/faqs/${chatbot.value.chatbotId}/${id}`
      );

      return response;
    }
  } catch (error) {
    console.error(error);
  }
};
