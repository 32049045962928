import { categories, chatbot } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const getCategories = async (): Promise<string[]> => {
  if (chatbot.value) {
    try {
      const client = HubbotApiHttpClient.instance;

      const response = await client.get<string[]>(
        `/faqs/${chatbot.value?.chatbotId}/categories`
      );

      const sortedCategories = response.sort();

      if (response) {
        categories.value = sortedCategories;
        return sortedCategories;
      }
    } catch (error) {
      console.error(error);
    }
  }

  categories.value = [];
  return [];
};
