import { DeleteItemApiResponse } from './types';

import { itemsConfig } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const deleteItem = async (id: string) => {
  try {
    const client = HubbotApiHttpClient.instance;

    if (itemsConfig.value) {
      const response = await client.delete<DeleteItemApiResponse>(
        `/recommender/${itemsConfig.value.companyId}/items/${id}`
      );

      return response;
    }
  } catch (error) {
    console.error(error);
  }
};
