import { colors } from '../colors';

type Colors = keyof typeof colors;

export const OverrideChip = ({
  variant,
  color,
}: {
  variant: 'filled' | 'outlined';
  color: Colors;
}) => ({
  props: { variant, color },
  style: {
    backgroundColor: colors[color][50],
    color: colors[color][700],
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
    paddingTop: '1px',
    paddingBottom: '1px',
    height: 'auto',
  },
});

export const OverrideChipFilled = () => {
  const colors: Colors[] = ['primary', 'secondary', 'warning', 'error', 'info'];

  return colors.map((color) => OverrideChip({ variant: 'filled', color }));
};
