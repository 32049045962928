export const colors = {
  primary: {
    main: '#473592',
    50: '#DAD0FF',
    100: '#BCA8FD',
    200: '#A890FB',
    300: '#9378F8',
    400: '#7D60F5',
    500: '#673AB7',
    600: '#5E35B1',
    700: '#512DA8',
    800: '#4527A0',
    900: '#311B92',
  },
  secondary: {
    main: '#1ABC9C',
    50: '#DCF3EE',
    100: '#AAE2D4',
    200: '#6FD0B8',
    300: '#1ABC9C',
    400: '#00AD87',
    500: '#009C74',
    600: '#008F68',
    700: '#007F58',
    800: '#006F4B',
    900: '#00522F',
  },
  error: {
    main: '#DA2560',
    900: '#8A174F',
    800: '#AF1D57',
    700: '#C4225B',
    600: '#DA2560',
    500: '#EB2963',
    400: '#F33C6A',
    300: '#F95E7C',
    200: '#F97F8D',
    100: '#FAA1A8',
    50: '#FBCCC2',
  },
  warning: {
    main: '#F3AC1E',
    900: '#EF6D17',
    800: '#F18A1A',
    700: '#F29A1C',
    600: '#F3AC1E',
    500: '#F4B822',
    400: '#F5C52B',
    300: '#F6D03C',
    200: '#F7D74E',
    100: '#F8DE5F',
    50: '#FDF4E6',
  },
  info: {
    main: '#3B86FF',
    900: '#3840b7',
    800: '#3b61d7',
    700: '#3b73ea',
    600: '#3b86ff',
    500: '#3895ff',
    400: '#4aa5ff',
    300: '#68b6ff',
    200: '#94cbff',
    100: '#bedeff',
    50: '#e4f2ff',
  },
};
