/* eslint-disable react-func/max-lines-per-function */
import { getItems } from './get-items';
import { getFaqs } from './get-faqs';

import { selectedView } from '@/signals';

export const getAllData = async () => {
  if (selectedView.value === 'recommender') {
    await getItems();
  } else {
    await getFaqs();
  }
};
