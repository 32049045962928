import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { PAGE_SIZES } from '@/constants/pagination';
import { getItems } from '@/request/hubbot-api';
import { itemsPerPage } from '@/signals';

export const PaginationSizeSelectItem = () => {
  const handleSizeChange = async (newSize: number): Promise<void> => {
    itemsPerPage.value = newSize;
    await getItems();
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
        <Select
          size="small"
          labelId="pagination-size-select-label"
          id="pagination-size"
          value={itemsPerPage.value}
          onChange={(e) => {
            if (e.target.value && typeof e.target.value === 'number') {
              void handleSizeChange(e.target.value);
            }
          }}
        >
          {PAGE_SIZES.map((size) => {
            return (
              <MenuItem key={'page-size-' + size} value={size}>
                {size}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
