import { Box, Chip, IconButton, Paper, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SellIcon from '@mui/icons-material/Sell';

import { DeleteModal } from './DeleteModal.component';

import { Item, crudMode, selectedItem } from '@/signals';
import { deleteFaq } from '@/request/hubbot-api';

interface ItemCardProps {
  item: Item;
  isSelected: boolean;
  updateSelectedItem: (item: Item) => void;
}

export const ItemCard = ({
  item,
  isSelected,
  updateSelectedItem,
}: ItemCardProps) => {
  const handleItemClick = () => {
    selectedItem.value = item;
    updateSelectedItem(item);
  };

  return (
    <Paper
      className={`mb-2 border-[1px] border-gray-400 ${
        isSelected ? 'selected' : ''
      }`}
      sx={{ display: 'flex', flexDirection: 'row' }}
      onClick={handleItemClick}
    >
      <Paper sx={{ background: '#DAD0FF' }}>
        <SellIcon sx={{ color: '#9378F8' }} />
      </Paper>
      <Box
        pl={2}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Typography fontWeight={600}>{item.name}</Typography>
        <div className="flex-between items-center overflow-hidden rounded">
          <div className="flex items-center">
            <Chip
              key={item.category}
              size="small"
              color="primary"
              label={item.category}
              variant="filled"
            />
          </div>
          <IconButton disabled={true} />
          {crudMode.value && (
            <DeleteModal
              button={
                <IconButton
                  disabled={true}
                  onClick={() => {
                    void deleteFaq(item.id);
                  }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              }
            />
          )}
        </div>
        <Typography
          variant="caption"
          color="gray"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        ></Typography>
      </Box>
    </Paper>
  );
};
