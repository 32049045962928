import { InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { categoryFilter } from '@/signals';

export const FilterSearchBar = () => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    categoryFilter.value = e.target.value;
  };

  return (
    <div>
      <TextField
        fullWidth
        type="text"
        size="small"
        value={categoryFilter.value}
        onChange={handleInputChange}
        placeholder="Buscar categoría"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
