import { Website } from '@cliengo/types';

import { CliengoAPIResponse } from './types';

import { CliengoApiHttpClient } from '@/utils/http-client';
import { selectedWebsite, websites } from '@/signals';

export const getSitesUrl = '/sites';

/**
 * Gets the list of websites for a company set in the JWT
 * @param jwt a valid Cliengo JWT containing company information
 * @returns an array of websites
 */
export async function getWebsites(): Promise<Website[]> {
  const client = CliengoApiHttpClient.instance;

  const { results } = await client.get<CliengoAPIResponse<Website>>(
    `${getSitesUrl}`
  );

  websites.value = results;
  selectedWebsite.value = results[0] as Website | null;

  return results;
}
