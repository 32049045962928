import React, { useState } from 'react';
import { Box } from '@mui/material';

import { QuestionCard } from '@/components';
import { Question, chatbot, questions } from '@/signals';

export const QuestionList = () => {
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null
  );

  const handleQuestionClick = (question: Question) => {
    setSelectedQuestion(question);
  };

  if (chatbot.value !== null) {
    return (
      <Box>
        <div>
          {questions.value.map((question: Question) => (
            <QuestionCard
              key={question.faqId}
              question={question}
              isSelected={question === selectedQuestion}
              updateSelectedQuestion={handleQuestionClick}
            />
          ))}
        </div>
      </Box>
    );
  }
  return null;
};
