import { cloneElement, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import InputTags from './InputTags.component'; // Asegúrate de que la ruta sea correcta

import { useDisclosure } from '@/hooks/useDisclosure';
import { createItem, AddItem, getItems } from '@/request/hubbot-api';
import { categories, isLoading, itemsConfig } from '@/signals';
import { scrapItem } from '@/request/hubbot-api/scrap-item';

interface AddItemModalProps {
  button: React.ReactElement;
}

export const AddItemModal = ({ button }: AddItemModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const randomNumber = Math.floor(Math.random() * 100000000);
  const externalId = 'cliengo' + randomNumber;
  const defaultItem: AddItem = {
    name: '',
    description: '',
    category: '',
    tags: [],
    provider: 'manual',
    companyId: itemsConfig.value?.companyId ?? '',
    externalId,
    url: '',
  };
  const [item, setItem] = useState<AddItem>(defaultItem);
  const trimmedCategory = item.name.trim();
  const trimmedQuestion = item.description?.trim();
  const trimmedAnswer = item.name.trim();
  const trimmedUrl = item.url?.trim();
  const tagsValidate = Array.isArray(item.tags) ? item.tags : [item.tags];
  const liteRecomender = itemsConfig.value?.liteRecomender ?? false;
  const defaultCategory = 'GLOBAL';

  const scrap = async () => {
    const categoryToFind = item.category ?? defaultCategory;
    const allParameters = itemsConfig.value?.parameters;

    if (allParameters === undefined) {
      throw new Error('parameters not found');
    }

    const parameters =
      allParameters.find((param) => param.category === categoryToFind) ||
      allParameters.find((param) => param.category === defaultCategory);

    const url = item.url ?? '';

    if (parameters === undefined) {
      throw new Error('parameters not found');
    }
    const productDetails = parameters.product_details;
    const response = await scrapItem(url, productDetails);

    return response;
  };

  async function handleAddQuestion() {
    isLoading.value = true;
    try {
      if (liteRecomender) {
        const newItem = await scrap();

        const mixItem = { ...item, ...newItem };

        if (!mixItem) {
          throw new Error('No se pudo obtener el nombre del item');
        }
        await createItem(mixItem as AddItem);
      } else {
        await createItem(item);
      }
      await getItems();
    } catch (e) {
      console.error(e);
    } finally {
      setItem(defaultItem);
    }
    onClose();
    isLoading.value = false;
  }

  const handleInputChange = (key: string, value: string | string[]) => {
    const newItem = {
      ...item,
      [key]: value,
    };

    setItem(newItem);
  };
  const clonedButton = cloneElement(button, { onClick: onOpen });

  return (
    <>
      {clonedButton}

      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="flex-center">
          <div className="flex items-center gap-2 font-bold	">Agregar item</div>
        </DialogTitle>
        {!liteRecomender ? (
          <DialogContent className="w-[488px] h-[428px]">
            <div className="mb-4 mt-2">
              <Autocomplete
                fullWidth
                freeSolo
                value={item?.category}
                options={categories.value}
                size="small"
                onChange={(event, value) => {
                  handleInputChange('category', value || '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="category"
                    label="Categoría"
                    onChange={(event) => {
                      const { name, value } = event.target;

                      handleInputChange(name, value);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <TextField
                name="name"
                fullWidth
                label="Nombre"
                size="small"
                value={item?.name}
                onChange={(event) => {
                  const { name, value } = event.target;

                  handleInputChange(name, value);
                }}
              />
            </div>
            <div className="mb-4">
              <TextField
                name="description"
                fullWidth
                label="Descripción"
                multiline
                size="small"
                value={item?.description}
                onChange={(event) => {
                  const { name, value } = event.target;

                  handleInputChange(name, value);
                }}
                inputProps={{ maxLength: 500, style: { maxHeight: '256px' } }}
              />
            </div>
            <div className="mb-4">
              <InputTags
                tags={tagsValidate || []}
                onChange={(newTags) => {
                  handleInputChange('tags', newTags);
                }}
                options={categories.value}
              />
            </div>
            {
              <div className="mb-4">
                <TextField
                  name="source"
                  fullWidth
                  label="Fuente"
                  size="small"
                  value={item?.url}
                  onChange={(event) => {
                    const { name, value } = event.target;

                    handleInputChange(name, value);
                  }}
                />
              </div>
            }
          </DialogContent>
        ) : (
          <DialogContent className="w-[488px] h-[240px]">
            {isLoading.value ? (
              <Box
                height={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <CircularProgress size={100} />
              </Box>
            ) : (
              <>
                <div className="mb-4 mt-2">
                  <Autocomplete
                    fullWidth
                    freeSolo
                    value={item?.category}
                    options={categories.value}
                    size="small"
                    onChange={(event, value) => {
                      handleInputChange('category', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="category"
                        label="Categoría"
                        onChange={(event) => {
                          const { name, value } = event.target;

                          handleInputChange(name, value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="mb-4 mt-2">
                  <TextField
                    name="url"
                    fullWidth
                    multiline
                    maxRows={5}
                    label="URL"
                    size="small"
                    value={item?.url}
                    onChange={(event) => {
                      const { name, value } = event.target;

                      handleInputChange(name, value);
                    }}
                  />
                </div>
              </>
            )}
          </DialogContent>
        )}
        <DialogActions className="!justify-center">
          <Button
            disabled={
              trimmedUrl
                ? (!trimmedUrl && !trimmedCategory) || isLoading.value
                : !trimmedCategory || !trimmedQuestion || !trimmedAnswer
            }
            onClick={() => {
              void handleAddQuestion();
            }}
            variant="contained"
            color="primary"
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
