import {
  Box,
  Card,
  CircularProgress,
  Divider,
  // Link,
  Stack,
} from '@mui/material';
import { useEffect } from 'react';

import {
  AddItemHeaderBar,
  //TO DO EditQuestionSideBar,
  EditItemSideBar,
  BarItemCategories,
  PaginationFooterItems,
  ItemList,
} from '@/components';
import { getWebsites } from '@/request/cliengo-api';
import { getItems } from '@/request/hubbot-api';
import {
  isLoading,
  isItemsLoading,
  selectedWebsite,
  websites,
  selectedView,
  category,
} from '@/signals';
import { getItemsCategories } from '@/request/hubbot-api/get-itemsCategories';

export const RecommenderBaseView = () => {
  selectedView.value = 'recommender';

  const initData = async () => {
    category.value = [];

    if (!websites.value.length) {
      isLoading.value = true;
      await getWebsites().catch((error) => console.error(error));
      isLoading.value = false;
    }
    if (selectedWebsite.value) {
      isLoading.value = true;
      await getItems();
      await getItemsCategories();
      isLoading.value = false;
    }
  };

  useEffect(() => {
    initData().catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <Box sx={{ width: '100%', maxWidth: '1366px' }}>
      <Stack direction={'column'} spacing={1} sx={{ marginTop: 2 }}>
        <Card sx={{ boxShadow: 1, borderRadius: 0.75 }}>
          <BarItemCategories />
        </Card>

        <Card
          sx={{
            boxShadow: 1,
            borderRadius: 0.75,
            minHeight: '800px',
            display: 'grid',
          }}
        >
          <AddItemHeaderBar />

          {isItemsLoading.value ? (
            <Box className="h-screen flex-center" maxHeight={550}>
              <CircularProgress size="4rem" thickness={5} />
            </Box>
          ) : (
            <div className="main-grid__container-list">
              <Box className="main-grid__list" maxHeight={650}>
                <ItemList />
              </Box>

              {
                <Box maxHeight={650}>
                  <EditItemSideBar />
                </Box>
              }
            </div>
          )}

          <Divider
            sx={{
              m: 0.2,
            }}
          />
          <PaginationFooterItems />
        </Card>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          py: 1,
        }}
      />
    </Box>
  );
};
