import { chatbot } from '@/signals';
import { TechnicalSolutionsPlatformHttpClient } from '@/utils/http-client/technical-solutions-platform-http-client';

type GptConfigResutl = {
  data: [
    {
      id: number;
      chatbotId: string;
    },
  ];
};

export const getGptConfigByWebsiteId = async (
  websiteId: string
): Promise<{
  id: number;
  chatbotId: string;
} | null> => {
  try {
    const client = TechnicalSolutionsPlatformHttpClient.instance;

    const response = await client.get<GptConfigResutl>(
      `/gpt-configs?filters[website][websiteId][$eq]=${websiteId}&pagination[limit]=1`
    ); // TO DO Verifcar caso en solo tienen recomendador,no anda search

    if (response.data.length) {
      const chatbotResult = {
        id: response.data[0].id,
        chatbotId: response.data[0].chatbotId,
      };

      chatbot.value = chatbotResult;
      return chatbotResult;
    }
  } catch (error) {}

  chatbot.value = null;
  return null;
};
