import {
  Box,
  Card,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import Iframe from 'react-iframe';

import { gerReport } from '@/request/hubbot-api/get-report';
import { isReportLoading, reportDetail } from '@/signals';

export const ReportView = () => {
  const initData = async () => {
    if (reportDetail.value === undefined) {
      isReportLoading.value = true;

      await gerReport();

      isReportLoading.value = false;
    }
  };

  useEffect(() => {
    initData().catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <Container
      sx={{
        p: 1,
        width: '100% !important',
        maxWidth: '1366px !important',
      }}
    >
      {isReportLoading.value ? (
        <Box className="h-screen flex-center" maxHeight={550}>
          <CircularProgress size="4rem" thickness={5} />
        </Box>
      ) : reportDetail?.value === null ? (
        <Container>
          <Card
            sx={{
              boxShadow: 1,
              borderRadius: 0.75,
              minHeight: '700px',
              display: 'grid',
            }}
          >
            <Typography sx={{ m: 2 }}>
              No hay ningún reporte configurado para esta empresa.
            </Typography>
          </Card>
        </Container>
      ) : (
        <Iframe
          url={reportDetail?.value?.url || ''}
          width="100%"
          height="800"
          id=""
          className=""
          display="inline"
          position="relative"
        />
      )}
    </Container>
  );
};
