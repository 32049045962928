import { Chatbot } from './types';

import { chatbot } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const getChatbotByWebsiteId = async (websiteId: string) => {
  try {
    const client = HubbotApiHttpClient.instance;

    const response = await client.get<Chatbot>(`/chatbot/website/${websiteId}`);

    if (response) {
      chatbot.value = response;
      return response;
    }
  } catch (error) {
    console.error(error);
  }

  chatbot.value = null;
  return null;
};
