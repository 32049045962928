import { EditItemApiResponse } from './types';

import { HUBBOT_API_URL } from '@/constants/environment';
import { Item, chatbot, selectedWebsite } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const updateItem = async (item: Item, id: string) => {
  const client = HubbotApiHttpClient.instance;

  try {
    if (chatbot.value) {
      // TO DO CAMBIAR EL EDPOINT RECOMENDER Y SACAR CHATBOT VALUE
      const response = await client.put<EditItemApiResponse>(
        `${HUBBOT_API_URL}/recommender/${selectedWebsite.value?.companyId}/items/${id}`,
        { ...item }
      );

      return response;
    }
  } catch (error) {
    console.error(error);
  }
};
