import React, { useState } from 'react';
import { Chip, TextField } from '@mui/material';

interface InputTagsProps {
  tags: string[]; // Cambia 'value' a 'tags'
  onChange: (tags: string[]) => void;
  options?: string[];
}

const InputTags: React.FC<InputTagsProps> = ({ tags, onChange }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      onChange([...tags, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    const newTags = tags.filter((tag) => tag !== tagToDelete);

    onChange(newTags);
  };

  return (
    <div>
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          onDelete={() => handleDeleteTag(tag)}
          style={{ margin: '0.2rem' }}
        />
      ))}
      <TextField
        label="Tags"
        size="small"
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
      />
    </div>
  );
};

export default InputTags;
