import { AddItem, AddItemApiResponse } from './types';

import { chatbot, itemsConfig } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const createItem = async (item: AddItem) => {
  try {
    const client = HubbotApiHttpClient.instance;

    {
      /* TO DO ACTUALIZAR ENDPOINT POST ITEM */
    }
    if (chatbot.value) {
      const response = await client.post<AddItemApiResponse>(
        `/recommender/${itemsConfig.value?.companyId}/items`,
        {
          ...item,
        }
      );

      return response;
    }
  } catch (error) {
    console.error(error);
  }
};
