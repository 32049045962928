/* eslint-disable @typescript-eslint/no-misused-promises */
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Switch,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Autocomplete,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';

import InputTags from './InputTags.component';
import { DeleteItemModal } from './DeleteItemModal.component';

import { images } from '@/constants/images';
import { getFaqs } from '@/request/hubbot-api';
import {
  Item,
  categories,
  chatbot,
  items,
  selectedItem,
  itemsConfig,
} from '@/signals';
import { updateItem } from '@/request/hubbot-api/update-item';

export const EditItemSideBar = () => {
  const handleFilterItem = (id: string) => {
    const fiteredItem = items.value.filter((item) => item.id === id);

    return fiteredItem[0];
  };

  const readOnly = itemsConfig.value?.liteRecomender ?? false;

  const handleInputChange = (
    key: string,
    value: string | string[] | boolean
  ) => {
    selectedItem.value = {
      ...selectedItem.value,
      [key]: value,
    } as Item;
  };

  const handleInputParams = (
    key: string,
    value: string | string[] | boolean
  ) => {
    if (selectedItem.value === null) {
      throw new Error('selectedItem.value is null');
    }
    // Accede a los parámetros dentro de selectedItem.value
    const updatedParams = {
      ...selectedItem.value.params,
      [key]: value,
    };

    // Actualiza el objeto selectedItem.value con los nuevos parámetros
    selectedItem.value = {
      ...selectedItem.value,
      params: updatedParams,
    } as Item;
  };

  const handleOnClick = async () => {
    if (selectedItem.value) {
      const { id: _id, name, description, params, tags } = selectedItem.value;

      const itemToChange = handleFilterItem(_id);

      // eslint-disable-next-line max-len, prettier/prettier
      Object.assign(itemToChange, { name, description, params, tags });

      try {
        void (await updateItem(itemToChange, selectedItem.value.id));
        await getFaqs();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const paramsList = () => {
    if (selectedItem.value?.params && selectedItem.value.params != null) {
      const paramsKeys = Object.keys(selectedItem.value.params);

      return paramsKeys.map((key: string) => {
        const { value } = selectedItem;
        const { params } = value || {};

        let valueText;

        if (value && params) {
          if (typeof params[key] === 'boolean') {
            valueText = (
              <div className="mb-1">
                <Typography>{key}</Typography>
                <Switch
                  name={key}
                  checked={params[key]}
                  onChange={(e) =>
                    handleInputParams(e.target.name, e.target.checked)
                  }
                />
              </div>
            );
          } else if (Array.isArray(value)) {
            valueText = 'Array';
          } else {
            valueText = (
              <div className="mb-5">
                <TextField
                  name={key}
                  fullWidth
                  size="small"
                  label={key}
                  value={params[key]}
                  onChange={(event) => {
                    handleInputParams(event.target.name, event.target.value);
                  }}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                />
              </div>
            );
          }
        }
        return <div key={key}>{valueText}</div>;
      });
    }
  };

  if (!selectedItem.value) {
    return (
      <Box height={'100%'} p={3}>
        <Typography variant="h6" fontWeight="bold" className="text-gray-800">
          Editar item
        </Typography>
        <Box
          sx={{
            p: 2,
          }}
        >
          {!!chatbot.value ? (
            <>
              <img src={images.selectQuestion} alt="select_question" />
              <Typography align="center">
                Seleccione un item para editar
              </Typography>
            </>
          ) : (
            <>
              <img src={images.chatbotNotFound} alt="chatbot_not_configured" />
              <Typography align="center">Chatbot no configurado</Typography>
            </>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Box height={'100%'} px={2} pt={2}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: 525,
        }}
      >
        <Box sx={{ minHeight: 400, maxHeight: 600, overflow: 'auto' }}>
          <Stack spacing={2}>
            <div className="pt-2">
              <Autocomplete
                fullWidth
                freeSolo
                value={selectedItem.value?.category}
                options={categories.value}
                size="small"
                onChange={(event, value) => {
                  handleInputChange('category', value || '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="category"
                    label="Categoría"
                    onChange={(event) => {
                      const { name, value } = event.target;

                      handleInputChange(name, value);
                    }}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                )}
                readOnly={readOnly}
              />
            </div>

            <div>
              <TextField
                name="name"
                fullWidth
                hiddenLabel
                size="small"
                label="Nombre"
                value={selectedItem.value?.name}
                onChange={(event) => {
                  const { name, value } = event.target;

                  handleInputChange(name, value);
                }}
                InputProps={{
                  readOnly: readOnly,
                }}
              />
            </div>

            <div className="mb-4 ">
              <TextField
                name="description"
                fullWidth
                hiddenLabel
                multiline
                size="small"
                label="Descripción"
                value={selectedItem.value?.description}
                onChange={(event) => {
                  const { name, value } = event.target;

                  handleInputChange(name, value);
                }}
                inputProps={{
                  maxLength: 2000,
                  style: { maxHeight: '500px' },
                  readOnly: readOnly,
                }}
              />
            </div>
            {readOnly && selectedItem.value?.tags?.length > 0 && (
              <div className="mb-4">
                <InputTags
                  tags={
                    Array.isArray(selectedItem.value?.tags)
                      ? selectedItem.value.tags
                      : selectedItem.value.tags.split(',')
                  }
                  onChange={(newTags) => {
                    handleInputChange('tags', newTags);
                  }}
                />
              </div>
            )}

            {selectedItem.value.params &&
              Object.keys(selectedItem.value.params).length !== 0 && (
                <div>
                  <Accordion sx={{ p: 0, borderColor: 'primary.main' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ flexDirection: 'row-reverse' }}
                    >
                      <Typography fontWeight={'bold'}>Parámetros </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{paramsList()}</AccordionDetails>
                  </Accordion>
                </div>
              )}
          </Stack>
        </Box>

        <Box sx={{ m: 1 }}>
          <div className="flex justify-end max-h-10">
            <DeleteItemModal
              button={
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <button className="flex">
                    <DeleteOutlineOutlinedIcon fontSize="small" color="error" />
                    <Typography color="GrayText">Eliminar producto</Typography>
                  </button>
                </Box>
              }
            />
            {/* fix that */}
            {readOnly ?? (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOnClick}
              >
                Guardar cambios
              </Button>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
};
