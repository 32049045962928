import { HttpClient } from './http-client';

import { KOTLER_API_URL } from '@/constants/environment';

/**
 * Singleton HttpClient for Hubbot API communications
 */
export class KotlerApiHttpClient extends HttpClient {
  private static _instance: KotlerApiHttpClient;

  private constructor() {
    super(KOTLER_API_URL);
  }

  public static get instance() {
    return this._instance || (this._instance = new KotlerApiHttpClient());
  }
}
