import { HttpClient } from './http-client';

import { HUBBOT_API_URL } from '@/constants/environment';

/**
 * Singleton HttpClient for Hubbot API communications
 */
export class HubbotApiHttpClient extends HttpClient {
  private static _instance: HubbotApiHttpClient;

  private constructor() {
    super(HUBBOT_API_URL);
  }

  public static get instance() {
    return this._instance || (this._instance = new HubbotApiHttpClient());
  }
}
