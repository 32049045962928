/* istanbul ignore file */
import axios, {
  AxiosHeaders,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

interface IHeadersHttpClient {
  [key: string]: string;
}

export interface IHttpClient {
  get<T>(path: string): Promise<T>;
  post<T>(path: string, body?: unknown): Promise<T>;
  put<T>(path: string, body?: unknown): Promise<T>;
  patch<T>(path: string, body?: unknown): Promise<T>;
  setHeader(keyHeader: string, valueHeader: string): void;
  setAuthorization(token: string): void;
}

declare module 'axios' {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-explicit-any
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export class HttpClient implements IHttpClient {
  private axiosInstance: AxiosInstance;
  private headers: IHeadersHttpClient = {};
  private readonly defaultHeaders: IHeadersHttpClient = {
    'Content-Type': 'application/json',
  };
  private readonly baseURL;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.axiosInstance = axios.create({
      baseURL,
    });
    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.axiosInstance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _initializeRequestInterceptor = () => {
    this.axiosInstance.interceptors.request.use(this._handleRequest);
  };

  private _handleRequest = (config: InternalAxiosRequestConfig) => {
    config.headers = {
      ...this.defaultHeaders,
      ...this.headers,
    } as AxiosHeaders;
    return config;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handleResponse = ({ data }: AxiosResponse): any => data;

  protected _handleError = (error: unknown) => Promise.reject(error);

  public setHeader = (keyHeader: string, valueHeader: string): void => {
    this.headers[keyHeader] = valueHeader;
  };

  public setAuthorization = (token: string): void => {
    this.headers.Authorization = 'Bearer '.concat(token);
  };

  public async get<T>(path: string): Promise<T> {
    const req = await this.axiosInstance.get<T>(path);

    return req;
  }

  public async post<T>(path: string, body: unknown = {}): Promise<T> {
    const req = await this.axiosInstance.post<T>(path, body);

    return req;
  }

  public async put<T>(path: string, body: unknown = {}): Promise<T> {
    const req = await this.axiosInstance.put<T>(path, body);

    return req;
  }

  public async patch<T>(path: string, body: unknown = {}): Promise<T> {
    const req = await this.axiosInstance.patch<T>(path, body);

    return req;
  }

  public async delete<T>(path: string): Promise<T> {
    const req = await this.axiosInstance.delete<T>(path);

    return req;
  }
}
