import React, { useState } from 'react';
import { Box } from '@mui/material';

import { ItemCard } from '@/components';
import { Item, items } from '@/signals';

export const ItemList = () => {
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  const handleItemClick = (item: Item) => {
    setSelectedItem(item);
  };

  return (
    <Box>
      <div>
        {}
        {items.value.map((item: Item) => (
          <ItemCard
            key={item.id}
            item={item}
            isSelected={item === selectedItem}
            updateSelectedItem={handleItemClick}
          />
        ))}
      </div>
    </Box>
  );
  // if (chatbot.value !== null) {
  // }
  return null;
};
