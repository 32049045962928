import { AddQuestion, AddQuestionApiResponse } from './types';

import { chatbot } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const createFaq = async (question: AddQuestion) => {
  try {
    const client = HubbotApiHttpClient.instance;

    if (chatbot.value) {
      const response = await client.post<AddQuestionApiResponse>(
        `/faqs/${chatbot.value.chatbotId}`,
        {
          ...question,
        }
      );

      return response;
    }
  } catch (error) {
    console.error(error);
  }
};
