/* eslint-disable @typescript-eslint/no-unused-vars */
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { cloneElement } from 'react';

import { useDisclosure } from '@/hooks/useDisclosure';
import { getItems } from '@/request/hubbot-api';
import { selectedItem } from '@/signals';
import { deleteItem } from '@/request/hubbot-api/delete-item';

interface DeleteModalProps {
  button: React.ReactElement;
}

export const DeleteItemModal = ({ button }: DeleteModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleDelete = async (): Promise<void> => {
    onClose();
    try {
      selectedItem.value && (await deleteItem(selectedItem.value.id));
      await getItems();
      selectedItem.value = null;
    } catch (e) {
      console.error(e);
    }
  };

  const clonedButton = cloneElement(button, { onClick: onOpen });

  return (
    <>
      {clonedButton}
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="flex items-center gap-2">
            <span className="bg-red-200 rounded-full h-10 w-10 flex-center">
              <WarningAmberIcon color="error" />
            </span>
            Eliminar producto
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Se va a borrar el producto de forma permanente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            onClick={(e) => {
              void handleDelete();
            }}
            variant="contained"
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
