import Cookies from 'js-cookie';

import { parseJwtCliengo } from './jwt-parser';
import { JwtCliengoPayload } from './types';

import {
  LOGIN_REDIRECT_URL,
  MOCKED_AUTH,
  MOCKED_JWT,
} from '@/constants/environment';
import { getWebsites } from '@/request/cliengo-api';
import { CliengoApiHttpClient } from '@/utils/http-client';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

/**
 * Redirects to login URL
 */
function redirectToUnauthorized(): void {
  window.location.href = `${LOGIN_REDIRECT_URL}`;
}

/**
 * Sets the JWT for Cliengo-Api calls
 * @param token a valid JWT
 */
function setAuthToken(token: string): void {
  CliengoApiHttpClient.instance.setAuthorization(token);
  HubbotApiHttpClient.instance.setAuthorization(token);
}

/**
 * Validates the authenticity of a Cliengo JWT trying to get the websites.
 * @param jwt the JWT set in the Cookies
 * @param onAuthenticationVerified a Callback to call if the token is correct
 * @returns true in case the token is correct
 */
async function verifyAuthToken(jwt: string): Promise<boolean> {
  try {
    const payload: JwtCliengoPayload | undefined = parseJwtCliengo(jwt);

    if (payload) {
      setAuthToken(jwt);

      await getWebsites();

      return true;
    }
  } catch (e) {}
  return false;
}

/**
 * Verify it the JWT in cookies is valid.
 * Sets the credentials for Cliengo-API calls in case of valid JWT.
 * Redirects to login in case of invalid JWT.
 */
async function authorizationGuard(): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

  const jwt: string | undefined = MOCKED_AUTH ? MOCKED_JWT : Cookies.get('jwt');

  if (!jwt) {
    redirectToUnauthorized();
    return;
  }

  const result = await verifyAuthToken(jwt);

  if (result) {
    return;
  }
  redirectToUnauthorized();
}

export default authorizationGuard;
