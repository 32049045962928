/* eslint-disable @typescript-eslint/no-misused-promises */
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  RadioGroup,
  MenuItem,
  Select,
  Radio,
  FormControlLabel,
  Autocomplete,
  Box,
  Button,
  Stack,
  Typography,
  InputLabel,
  FormControl,
  Divider,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import InputTags from './InputTags.component';

import { DeleteModal } from '@/components/DeleteModal.component';
import { images } from '@/constants/images';
import { getFaqs, updateFaq } from '@/request/hubbot-api';
import {
  Question,
  categories,
  chatbot,
  questions,
  selectedQuestion,
} from '@/signals';
interface Conditional {
  variable: string;
  value: string;
  content: string;
}
export const EditQuestionSideBar = () => {
  const [conditionals, setConditionals] = useState<Conditional[]>([]);

  const addConditional = () => {
    setConditionals([
      ...conditionals,
      { variable: '', value: '', content: '' },
    ]);
  };
  const handleChangeConditional = (
    index: number,
    field: keyof Conditional,
    value: string
  ) => {
    const nuevasPreguntas = [...conditionals];

    nuevasPreguntas[index][field] = value;
    setConditionals(nuevasPreguntas);
  };

  const isConditional = () => {
    const regex = /#\{IF/;

    return regex.test(selectedQuestion.value?.response || '');
  };

  const conditionalFormat = () => {
    setConditionals([]);
    const resultados = selectedQuestion.value?.response
      .split(/!#\{END IF\}/)
      .map((item) => item.trim())
      .filter((item) => item !== '');

    const regex = /!\s*#\{IF\s+([^=]+?)\s*=\s*([^}]+?)\}\s*([\s\S]*)/;

    resultados?.forEach((e) => {
      const matches = regex.exec(e);

      if (matches) {
        const variableName = matches[1];
        const variableValue = matches[2];
        const content = matches[3];

        setConditionals((prevConditionals) => [
          ...prevConditionals,
          { variable: variableName, value: variableValue, content },
        ]);
      } else {
        console.error("No se encontró la estructura 'IF...ENDIF'");
      }
    });
  };

  useEffect(() => {
    if (selectedQuestion?.value != null) {
      const isCond = isConditional();

      selectedQuestion.value.type = isCond ? 'conditional' : 'simple';
      if (isCond) {
        conditionalFormat();
      }
    }
    // TO DO fix this
  }, [selectedQuestion.value?.faqId]);

  useEffect(() => {
    const format = conditionals
      .map(
        (conditional) =>
          `!#{IF ${conditional.variable} = ${conditional.value}}${conditional.content}!#{END IF}`
      )
      .join('');

    if (selectedQuestion.value) {
      selectedQuestion.value.response = format;
    }
  }, [conditionals]);

  const handleFilterQuestion = (id: string) => {
    const fiteredQuestion = questions.value.filter(
      (question) => question.faqId === id
    );

    return fiteredQuestion[0];
  };

  const handleInputChange = (key: string, value: string | string[]) => {
    selectedQuestion.value = {
      ...selectedQuestion.value,
      [key]: value,
    } as Question;
  };
  const getValuesForSelectedLabel = (selectedLabel: string) => {
    const selectedOption = chatbot.value?.variables?.find(
      (option) => option.label === selectedLabel
    );

    return selectedOption ? selectedOption.values : [];
  };
  const hasAction =
    chatbot.value !== undefined &&
    chatbot.value !== null &&
    chatbot.value.actions !== undefined &&
    chatbot.value.actions.length > 0;

  const hasContionals =
    chatbot.value !== undefined &&
    chatbot.value !== null &&
    chatbot.value.variables !== undefined &&
    chatbot.value.variables.length > 0;

  const handleOnClick = async () => {
    if (selectedQuestion.value) {
      const {
        faqId: _id,
        category,
        question,
        response,
        source,
        tags,
        action,
      } = selectedQuestion.value;

      const questionToChange = handleFilterQuestion(_id);

      // eslint-disable-next-line max-len, prettier/prettier
      Object.assign(questionToChange, { category, question, response, source, tags, action });
      delete questionToChange['type'];

      try {
        void (await updateFaq(questionToChange, selectedQuestion.value.faqId));
        await getFaqs();
      } catch (e) {
        console.error(e);
      }
    }
  };

  if (!selectedQuestion.value) {
    return (
      <Box height={'100%'} px={3} pt={3}>
        <Typography variant="h6" fontWeight="bold" className="text-gray-800">
          Editar pregunta
        </Typography>
        <Box
          sx={{
            p: 2,
          }}
        >
          {!!chatbot.value ? (
            <>
              <img src={images.selectQuestion} alt="select_question" />
              <Typography align="center">
                Seleccione una pregunta para editar
              </Typography>
            </>
          ) : (
            <>
              <img src={images.chatbotNotFound} alt="chatbot_not_configured" />
              <Typography align="center">Chatbot no configurado</Typography>
            </>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box height={'100%'} m={2}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: 525,
        }}
      >
        <Box sx={{ minHeight: 400, maxHeight: 590, overflow: 'auto' }}>
          <Stack spacing={2}>
            <div className="pt-2">
              <Autocomplete
                fullWidth
                freeSolo
                value={selectedQuestion.value?.category}
                options={categories.value}
                size="small"
                onChange={(event, value) => {
                  handleInputChange('category', value || '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="category"
                    label="Categoría"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      const normalizedValue =
                        typeof value === 'string'
                          ? value.replace(/,/g, '')
                          : value;

                      handleInputChange(name, normalizedValue);
                    }}
                  />
                )}
              />
            </div>

            <div>
              <TextField
                name="question"
                fullWidth
                hiddenLabel
                size="small"
                label="Pregunta"
                value={selectedQuestion.value?.question}
                onChange={(event) => {
                  const { name, value } = event.target;

                  handleInputChange(name, value);
                }}
              />
            </div>
            {hasContionals && (
              <div>
                <Typography fontWeight={'bold'}>Tipo de respuesta</Typography>
                <RadioGroup
                  aria-label="type-response"
                  name="type"
                  value={selectedQuestion.value?.type || 'simple'}
                  onChange={(event) => {
                    const { name, value } = event.target;

                    handleInputChange(name, value);

                    if (value == 'conditional') {
                      setConditionals([
                        {
                          variable: '',
                          value: '',
                          content: selectedQuestion?.value
                            ? selectedQuestion?.value.response
                            : '',
                        },
                      ]);
                    }
                    if (
                      value == 'simple' &&
                      isConditional() &&
                      selectedQuestion.value
                    ) {
                      const concatenatedContent = conditionals
                        .map((obj) => obj.content)
                        .join('\n\n');

                      selectedQuestion.value.response = concatenatedContent;
                    }
                  }}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value="simple"
                    control={<Radio />}
                    label="Simple"
                  />
                  <FormControlLabel
                    value="conditional"
                    control={<Radio />}
                    label="Condicional"
                  />
                </RadioGroup>
              </div>
            )}
            <div>
              {selectedQuestion.value?.type === 'conditional' &&
                conditionals.length > 0 &&
                conditionals.map((pregunta, index) => (
                  <Box key={'rep-' + index} mb={4}>
                    <Box mb={1}>
                      <Typography
                        fontWeight={600}
                        color={'primary'}
                      >{`Respuesta ${index + 1}`}</Typography>
                      <Divider />
                    </Box>
                    <Stack
                      direction="row"
                      p={1}
                      spacing={1}
                      alignItems={'center'}
                    >
                      <Typography fontWeight={'600'}> Si</Typography>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="first-var-select">
                          Variable
                        </InputLabel>
                        <Select
                          label="Variable"
                          labelId="first-var-select-label"
                          id="first-var-select"
                          value={conditionals[index].variable}
                          size="small"
                          onChange={(event) => {
                            handleChangeConditional(
                              index,
                              'variable',
                              event.target.value
                            );
                          }}
                          sx={{ minWidth: 150 }}
                        >
                          {chatbot.value?.variables &&
                            chatbot.value?.variables.map((variable) => (
                              <MenuItem
                                key={variable.key}
                                value={variable.label}
                              >
                                {variable.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <Typography whiteSpace={'nowrap'} fontWeight={'600'}>
                        es igual a
                      </Typography>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="second-select-label">
                          Valor
                        </InputLabel>
                        <Select
                          label="Valor"
                          labelId="second-select-label"
                          id="second-select"
                          value={conditionals[index].value}
                          size="small"
                          onChange={(event) => {
                            handleChangeConditional(
                              index,
                              'value',
                              event.target.value
                            );
                          }}
                          sx={{ minWidth: 150 }}
                        >
                          {chatbot.value?.variables &&
                            getValuesForSelectedLabel(
                              conditionals[index].variable
                            ).map((variable, index) => (
                              <MenuItem key={index} value={variable}>
                                {variable}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Stack>
                    <TextField
                      size="small"
                      hiddenLabel
                      multiline
                      minRows={2}
                      maxRows={8}
                      label={`Respuesta ${index + 1}`}
                      value={pregunta.content}
                      onChange={(event) =>
                        handleChangeConditional(
                          index,
                          'content',
                          event.target.value
                        )
                      }
                      fullWidth
                      margin="normal"
                      inputProps={{
                        maxLength: 2000,
                        style: { maxHeight: '500px' },
                      }}
                    />
                  </Box>
                ))}
              {selectedQuestion.value?.type === 'conditional' && (
                <Button fullWidth onClick={addConditional}>
                  + Nueva respuesta
                </Button>
              )}
            </div>

            {selectedQuestion.value?.type !== 'conditional' && (
              <TextField
                name="response"
                fullWidth
                hiddenLabel
                multiline
                maxRows={8}
                size="small"
                label="Respuesta"
                value={selectedQuestion.value?.response}
                onChange={(event) => {
                  const { name, value } = event.target;

                  handleInputChange(name, value);
                }}
                inputProps={{
                  maxLength: 2000,
                  style: { maxHeight: '500px' },
                }}
              />
            )}

            <div className="mb-4">
              <InputTags
                tags={selectedQuestion.value?.tags || []}
                onChange={(newTags) => {
                  handleInputChange('tags', newTags);
                }}
              />
            </div>
            <div className="mb-4">
              <TextField
                name="source"
                fullWidth
                label="Fuente / URL"
                size="small"
                value={selectedQuestion.value?.source || ''}
                onChange={(event) => {
                  const { name, value } = event.target;

                  handleInputChange(name, value);
                }}
              />
            </div>
            <div className="mb-4">
              {hasAction && (
                <Autocomplete
                  fullWidth
                  freeSolo
                  value={selectedQuestion.value?.action || ''}
                  options={chatbot.value?.actions || []}
                  size="small"
                  onChange={(event, value) => {
                    handleInputChange('action', value || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="action"
                      label="Acción"
                      onChange={(event) => {
                        const { name, value } = event.target;

                        handleInputChange(name, value);
                      }}
                    />
                  )}
                />
              )}
            </div>
          </Stack>
        </Box>

        <Box sx={{ m: 1 }}>
          <div className="flex justify-between max-h-10">
            <DeleteModal
              button={
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <button className="flex">
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                    <Typography color="GrayText">Eliminar pregunta</Typography>
                  </button>
                </Box>
              }
            />
            {/* fix that */}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOnClick}
            >
              Guardar cambios
            </Button>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
