import { ReportDetail } from './types';

import { reportDetail } from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const gerReport = async () => {
  try {
    const client = HubbotApiHttpClient.instance;

    const response = await client.get<ReportDetail>(`/chatbot/report`);

    if (response) {
      reportDetail.value = response;
      return response;
    }
  } catch (error) {
    reportDetail.value = null;
    return null;
  }
};
