import { HttpClient } from './http-client';

import {
  TECHNICAL_SOLUTIONS_PLATFORM_URL,
  TECHNICAL_SOLUTIONS_TOKEN,
} from '@/constants/environment';

/**
 * Singleton HttpClient for Hubbot API communications
 */
export class TechnicalSolutionsPlatformHttpClient extends HttpClient {
  private static _instance: TechnicalSolutionsPlatformHttpClient;

  private constructor() {
    super(TECHNICAL_SOLUTIONS_PLATFORM_URL);
    this.setAuthorization(TECHNICAL_SOLUTIONS_TOKEN);
  }

  public static get instance() {
    return (
      this._instance ||
      (this._instance = new TechnicalSolutionsPlatformHttpClient())
    );
  }
}
