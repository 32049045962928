/* eslint-disable react-func/max-lines-per-function */
import { FaqsApiResponse } from './types';

import {
  category,
  chatbot,
  currentPage,
  isQuestionsLoading,
  questionFilter,
  questions,
  questionsPerPage,
  selectedQuestion,
  totalPages,
  totalQuestions,
} from '@/signals';
import { HubbotApiHttpClient } from '@/utils/http-client/hubbot-api-http-client';

export const getFaqs = async () => {
  isQuestionsLoading.value = true;
  try {
    const client = HubbotApiHttpClient.instance;

    selectedQuestion.value = null;

    if (chatbot.value) {
      const response = await client.get<FaqsApiResponse>(buildGetUrl());

      questions.value = response.result;
      totalQuestions.value = response.meta.total;
      totalPages.value = Math.ceil(
        response.meta.total / questionsPerPage.value
      );

      return response.result;
    }

    questions.value = [];
    currentPage.value = 1;
    totalQuestions.value = 0;
    totalPages.value = 1;
    return [];
  } catch (error) {
    console.error(error);
  } finally {
    isQuestionsLoading.value = false;
  }
};

const buildGetUrl = () => {
  let url = `/faqs/${chatbot.value?.chatbotId}?limit=${
    questionsPerPage.value
  }&offset=${(currentPage.value - 1) * questionsPerPage.value}`;

  if (category.value && category.value.length > 0) {
    const flattenedCategories = category.value.flat().join(',');

    url = `${url}&categories=${flattenedCategories}`;
  }
  if (questionFilter.value) {
    url = `${url}&question=${questionFilter.value}`;
  }

  return url;
};
