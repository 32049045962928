import { Website } from '@cliengo/types';
import { computed, effect, signal } from '@preact/signals-react';

import { Question, Item, ItemsConfig } from './types';

import { JwtCliengoPayload } from '@/auth/types';
import { PAGE_SIZES } from '@/constants/pagination';
import { Chatbot, ReportDetail } from '@/request/hubbot-api';
import { viewName } from '@/views/constants';

export const selectedView = signal<viewName>('knowledgeBase');
export const websites = signal<Website[]>([]);
export const selectedWebsite = signal<Website | null>(null);
export const jwtCliengoPayload = signal<JwtCliengoPayload | null>(null);
export const chatbot = signal<Chatbot | null | undefined>(undefined);
export const reportDetail = signal<ReportDetail | null | undefined>(undefined);

export const isLoading = signal<boolean>(true);
export const isReportLoading = signal<boolean>(true);
export const isQuestionsLoading = signal<boolean>(false);
export const questions = signal<Question[]>([]);
export const questionFilter = signal('');

export const categories = signal<string[]>([]);
export const category = signal<string[]>([]);
export const categoryFilter = signal('');

export const selectedQuestion = signal<Question | null>(null);

export const currentPage = signal(1);
export const questionsPerPage = signal(PAGE_SIZES[0]);
export const totalQuestions = signal(0);
export const totalPages = signal(1);

// recommender items
export const items = signal<Item[]>([]);
export const selectedItem = signal<Item | null>(null);
export const itemFilter = signal('');
export const isItemsLoading = signal<boolean>(false);
export const totalItems = signal(0);
export const itemsPerPage = signal(PAGE_SIZES[0]);
export const crudMode = signal<boolean>(false);
export const itemsConfig = signal<ItemsConfig | null>(null);

//Rcomender lite
export const reloadProducts = signal<boolean>(false);

/* This is to manage the category name filter inside the category selector*/
export const filterCategories = computed(() => {
  const filteredCategoriesBySearch = categories.value.filter((category) =>
    category.toLowerCase().includes(categoryFilter.value.toLowerCase())
  );

  return filteredCategoriesBySearch;
});

effect(() => {
  questionFilter.value;
  currentPage.value = 1;
});
