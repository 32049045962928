import { Chip, IconButton, Paper, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { DeleteModal } from './DeleteModal.component';

import { Question, selectedQuestion } from '@/signals';
import { deleteFaq } from '@/request/hubbot-api';

interface QuestionCardProps {
  question: Question;
  isSelected: boolean;
  updateSelectedQuestion: (question: Question) => void;
}

export const QuestionCard = ({
  question,
  isSelected,
  updateSelectedQuestion,
}: QuestionCardProps) => {
  const handleQuestionClick = () => {
    selectedQuestion.value = question;
    updateSelectedQuestion(question);
  };

  return (
    <Paper
      className={`mb-2 border-[1px] border-gray-400 ${
        isSelected ? 'selected' : ''
      }`}
      onClick={handleQuestionClick}
    >
      <div className="flex-between items-center ">
        <div className="flex  items-center">
          <Chip
            size="small"
            color="primary"
            label={question.category}
            variant="filled"
          />
        </div>
        <DeleteModal
          button={
            <IconButton
              onClick={() => {
                void deleteFaq(question.faqId);
              }}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
      <Typography>{question.question}</Typography>
      <Typography
        variant="caption"
        color="gray"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {question.response}
      </Typography>
    </Paper>
  );
};
