import AddIcon from '@mui/icons-material/Add';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import { useState } from 'react';

import { AddItemModal } from '@/components';
import { Item, itemsConfig, selectedItem } from '@/signals';
import { scrapItem } from '@/request/hubbot-api/scrap-item';
import { updateItem } from '@/request/hubbot-api/update-item';
import { getItems } from '@/request/hubbot-api';

export const AddItemHeaderBar = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const liteRecomender = itemsConfig.value?.liteRecomender ?? false;

  const scrap = async () => {
    const defaultCategory = 'GLOBAL';
    const categoryToFind = selectedItem.value?.category ?? defaultCategory;

    const allParameters = itemsConfig.value?.parameters;

    if (allParameters === undefined) {
      throw new Error('parameters not found');
    }

    const parameters =
      allParameters.find((item) => item.category === categoryToFind) ||
      allParameters.find((param) => param.category === defaultCategory);

    const url = selectedItem.value?.url ?? '';

    if (parameters === undefined) {
      throw new Error('parameters not found');
    }

    const response = await scrapItem(url, parameters.product_details);

    return response;
  };

  const handleReloadItem = async () => {
    setIsUpdating(true);
    try {
      const newItem = await scrap();
      const mixItem = { ...selectedItem.value, ...newItem };

      if (newItem === undefined) {
        throw new Error('Error scraping item');
      }

      await updateItem(mixItem as Item, mixItem.id ?? '');

      await getItems();
    } catch (e) {
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box className="main-grid__container-list">
      <Box sx={{ px: 2 }}>
        <Grid container>
          <Grid
            item
            xs={6}
            md={25}
            sx={{ alignItems: 'center', display: 'inline-flex' }}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <ShoppingCartIcon color="primary" fontSize="medium" />
              <Typography
                fontWeight="bold"
                variant="h6"
                className="text-gray-800"
              >
                Catálogo de productos y servicios
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            px: 2,
            maxWidth: '600px',
          }}
        >
          {selectedItem.value && liteRecomender && (
            <Box sx={{ mr: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={
                  isUpdating ? (
                    <CircularProgress size={15} />
                  ) : (
                    <LoopIcon fontSize="small" />
                  )
                }
                onClick={() => {
                  handleReloadItem().catch((e) => console.error(e));
                }}
              >
                Actualizar producto
              </Button>
            </Box>
          )}

          <AddItemModal
            button={
              <Button
                variant="contained"
                startIcon={<AddIcon fontSize="small" />}
                disabled={!itemsConfig.value}
              >
                Agregar producto
              </Button>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
