import { Box, Grid, Stack, Typography } from '@mui/material';

import { SearchBar, WebsitesSelect } from '@/components';
import { chatbot } from '@/signals';

export const MainBarSelectors = () => {
  return (
    <Grid container>
      <Grid item xs={6} md={5} px={2}>
        <Box
          sx={{
            justifyContent: 'center',
          }}
        >
          <Stack spacing={1}>
            <WebsitesSelect />
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={6} md={7} px={2}>
        <Stack spacing={1}>
          {chatbot.value === null ? (
            <Typography className="!mb-3 text-gray-800">
              Este canal no tiene un chatbot con inteligencia artificial
              configurado
            </Typography>
          ) : (
            <SearchBar />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
