import { InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { FilterComponent } from './Filter.component';

import { questionFilter, selectedView } from '@/signals';
import { getFaqs, getItems } from '@/request/hubbot-api';

export const SearchBar = () => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    questionFilter.value = e.target.value;
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      selectedView.value == 'recommender' ? void getItems() : void getFaqs();
    }
  };

  return (
    <div>
      <TextField
        size="small"
        className="bg-gray-100"
        fullWidth
        type="text"
        value={questionFilter.value}
        onChange={handleInputChange}
        onKeyUp={handleKeyUp}
        placeholder="Buscar..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <FilterComponent />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
