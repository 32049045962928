/* eslint-disable import/order */
import { ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { theme } from './theme/theme';

import authorizationGuard from '@/auth/authorizationGuards';
import './app.css';
import { MainView } from './views/MainView';

export const App = () => {
  const init = async (): Promise<void> => {
    void (await authorizationGuard());
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className="bg-pattern">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainView />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};
