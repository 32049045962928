export const APP_NAME: Optional<string> = process.env.APP_NAME;
export const CLIENGO_API_URL = String(process.env.CLIENGO_API_URL);
export const HUBBOT_API_URL = String(process.env.HUBBOT_API_URL);
export const TECHNICAL_SOLUTIONS_PLATFORM_URL = String(
  process.env.TECHNICAL_SOLUTIONS_PLATFORM_URL
);
export const TECHNICAL_SOLUTIONS_TOKEN = String(
  process.env.TECHNICAL_SOLUTIONS_TOKEN
);
export const LOGIN_URL = String(process.env.LOGIN_URL);
export const MOCKED_AUTH = process.env.MOCKED_AUTH === 'true';
export const MOCKED_JWT = String(process.env.MOCKED_JWT);
export const LOGIN_REDIRECT_URL = String(process.env.LOGIN_REDIRECT_URL);
export const KOTLER_API_URL = String(process.env.KOTLER_API_URL);
